
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { MultiChoiceCard, DotationOperation } from '@/models'

import Pagination from '@/components/Pagination/index.vue'
import { clipboardSuccess } from '@/utils/clipboard'

@Component({
  name: 'MultiChoiceCardsTable',
  components: {
    Pagination,
    MultiChoiceCardDialog: () => import('./MultiChoiceCardDialog.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private operation!: DotationOperation

  private multiChoiceCards: MultiChoiceCard[] = []
  private MultiChoiceCardsLoading = false

  private showMultiChoiceCardDialog = false
  private clipboardSuccess = clipboardSuccess

  private pagination = {
    page: 1,
    limit: 20
  }

  @Watch('operation', { immediate: true })
  async getMultiChoiceCards() {
    this.MultiChoiceCardsLoading = true

    const { data } =
      await MultiChoiceCard
        .includes(['customer', 'multiChoiceGiftCard'])
        .where({ dotationOperationId: this.operation.id })
        .order({ createdAt: 'desc' })
        .page(this.pagination.page)
        .per(this.pagination.limit)
        .all()

    if (data.length === 0 && this.pagination.page > 1)
      this.pagination.page -= 1
    else
      this.multiChoiceCards = data

    this.MultiChoiceCardsLoading = false
  }

  truncateID(id: string): string {
    return `${id.slice(0, 4)} .... ${id.substr(id.length - 4)}`
  }

  addInList(multiChoiceCard: MultiChoiceCard): void {
    this.multiChoiceCards.unshift(multiChoiceCard)
  }
}
