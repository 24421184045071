
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DotationOperation } from '@/models'

@Component({
  name: 'CardOperation'
})
export default class extends Vue {
  @Prop({ required: true }) private operation!: DotationOperation
  @Prop({ required: true }) private selectedOperation!: DotationOperation

  get percentage(): number {
    return Number((((this.operation.maximumAmount - this.operation.remainingAmount) / this.operation.maximumAmount) * 100).toFixed(1))
  }
}
