
import { Component, Vue } from 'vue-property-decorator'

import BudgetMove from '@/models/BudgetMove'
import Pagination from '@/components/Pagination/index.vue'

@Component({
  name: 'HistoryFundsTable',
  components: {
    Pagination
  }
})
export default class extends Vue {
  private budgetMoves: BudgetMove[] = []
  private totalBudgetMoves = 0
  private budgetMovesLoading = true
  private headersByDate: any[] = []

  pagination = {
    page: 1,
    limit: 10
  }

  created() {
    this.getBudgetMoves()
  }

  private async getBudgetMoves() {
    this.budgetMovesLoading = true

    const { data, meta } =
        await BudgetMove
            .includes([{ source: ['customer', 'dotationOperation', 'multiChoiceGiftCard'] }])
            .page(this.pagination.page)
            .per(this.pagination.limit)
            .order({ createdAt: 'desc' })
            .stats({ total: 'count' })
            .all()

    this.budgetMoves = data
    this.balanceMovePerDate()
    this.totalBudgetMoves = meta.stats.total.count
    this.budgetMovesLoading = false
  }

  private sourceName(budgetMove: BudgetMove) {
    if (budgetMove.sourceType === 'BankTransfer')
      return `#${budgetMove.source.reference}`
    else if (budgetMove.source.customer)
      `${budgetMove.source.customer.firstname} ${budgetMove.source.customer.lastname}`
  }

  private balanceMovePerDate() {
    this.headersByDate = []
    this.budgetMoves.map((e, index) => {
      if (this.budgetMoves[index - 1] && this.$moment(this.budgetMoves[index - 1].createdAt).format('L') !== this.$moment(e.createdAt).format('L')) {
        this.headersByDate.push(index)
      }
    })
  }

  private setClassTrend(budgetMove: BudgetMove): string {
    if (budgetMove.sourceType === 'BankTransfer') return 'badge-success'
    else return 'badge-debit'
  }

  private setIconTrend(budgetMove: BudgetMove): string {
    if (budgetMove.sourceType === 'BankTransfer') return 'el-icon-top-right'
    else return 'el-icon-bottom-left'
  }
}
